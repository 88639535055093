import { useState } from 'react';
import ContactForm from './ContactForm';
import ContactResponse from './ContactResponse';

import './Contact.css';

const Contact = () => {
    const [reactState, setReactState] = useState({ contactFormResponseReceived: false, contactFormResponseOk: true, contactSectionHeight: 0 })

    function updateContactFormResponseState(responseReceived, responseOk, sectionHeight) {
        setReactState({ contactFormResponseReceived: responseReceived, contactFormResponseOk: responseOk, contactSectionHeight: sectionHeight });

        if (sectionHeight > 0)
            document.getElementById('contact-container').style.height = sectionHeight + "px";
    }

    const componentToRender = (!reactState.contactFormResponseReceived) ? <ContactForm parentUpdateContactFormResponseState={updateContactFormResponseState} /> : <ContactResponse contactFormResponseOk={reactState.contactFormResponseOk} />;

    return (
        <>
            <div id="contact-container" className="section-container">
                {componentToRender}
            </div>
        </>
    );
}

export default Contact;