import './ContactResponse.css';
import useImagesLoaded from "../../Hooks/useImagesLoaded";

const ContactResponse = (props) => {
    let heading = "";
    let text = "";

    if (props.contactFormResponseOk) {
        heading = "Your request has been received!";
        text = "A representative will reach out to you via your preferred contact method within 1 business day."

        window.localStorage.removeItem("contactFormData");
    }

    else {
        heading = "An error has occurred and your request was not received."
        text = "Wait a few minutes and try again. If the problem persists, please reach out to us by phone at (312) 444-0197. Thank you for your patience."
    }

    // useEffect() doesn't account for images that are loading, which means element positioning data won't be accurate. Use this custom hook instead which runs once all 
    // images are loaded. All element tops, lefts, etc. should be accurate at this point
    useImagesLoaded(() => {
        const backgroundElement = document.getElementById("background");
        const headerRect = document.getElementById("header").getBoundingClientRect();
        const contactTextContainerRect = document.getElementById("contact-text-container").getBoundingClientRect();

        backgroundElement.scrollTo(0, contactTextContainerRect.top + backgroundElement.scrollTop - (headerRect.height + 10));
    });


    return (
        <>
            <div id="contact-text-container" className="section-text-container">
                <div id="contact-text-header-1" className="section-text-header-1">Contact Us</div>
                <p id="contact-text-header-2">{heading}</p>
                <p>{text}</p>
            </div>
        </>
    );
}

export default ContactResponse;