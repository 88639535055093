import './Products.css';

import productsImage from '../static/images/products850.png';

const Products = (componentProps) => {
    const handleContactButtonClick = (name) => {
        componentProps.indexScrollToSection(name);
    }

    return (
        <>
            <div id="products-container" className="section-container">
                <div id="products-text-header-1" className="section-text-header-1">Products</div>
                <img id="products-text-header-image" src={productsImage} alt="Products"></img>

                <div id="products-text-container" className="section-text-container">
                    <p>Are you an on-site contractor or a professional that's always on the go? Do you face challenges daily keeping track of communications from your clients?</p>
                    <p>A suite of tools is currently being developed that will help offload many of these responsibilities that you have to your customers. Designed with ease of use in mind 
                        for both you and your clientele, they will allow you to focus more of your time where it's needed most &mdash; delivering results.</p>
                    <p>Integration with your existing site is
                        simple; we do all the heavy lifting. Don't have a site yet? No problem. Contact us using the form below and we'll work on setting everything up!</p>
                    <p>Updates will be posted to this page as completion nears, so be sure to check in on this section from time to time.</p>
                    <p>If you'd like more information, or have a special request, feel free to reach out to us and we'll be happy to go over the finer details with you!</p>

                    <button className="jump-to-contact-us-button" type="button" onClick={() => handleContactButtonClick("contact")}>Contact Us {">>>"}</button>
                </div>
            </div>
        </>
    );
}

export default Products;
