import './NavMenu.css';
import nhtLogo64 from '../static/images/nhtLogo64.png';

const NavMenu = (componentProps) => {
    const handleClick = (name) => {
        componentProps.indexScrollToSection(name);

        // TR - Close the menu
        document.getElementById("nav-menu-mobile-hamburger-checkbox").checked = false;
    }

    const RenderNavMenuItem = (props) => {
        return (
            <div className={props.className} onClick={() => handleClick(props.name)}>{props.name}</div>
        );
    }

    return (
        <>
            <div id="header">
                <div id="logo-and-company-name-container">
                    <div id="logo-and-company-name-flex-container" onClick={() => handleClick("Home")}>
                        <img id="top-left-logo-image" src={nhtLogo64} alt="Top Left Logo" /><span id="top-left-company-name">Nighthawk TEK</span>
                    </div>
                </div>

                <div id="nav-menu-container">
                    <nav id="nav-menu-items-flex-container">
                        <RenderNavMenuItem name="Services" className="nav-menu-item" />
                        <RenderNavMenuItem name="Products" className="nav-menu-item" />
                        <RenderNavMenuItem name="About" className="nav-menu-item" />
                        <RenderNavMenuItem name="Contact" className="nav-menu-item" />
                    </nav>
                </div>

                <div id="nav-menu-mobile-container">
                    <div id="nav-menu-mobile-flex-container">
                        <input id="nav-menu-mobile-hamburger-checkbox" type="checkbox" /><span id="testcheckmark"></span>
                        <div id="nav-menu-mobile-hamburger">
                            <div className="nav-menu-mobile-hamburger-line" />
                            <div className="nav-menu-mobile-hamburger-line" />
                            <div className="nav-menu-mobile-hamburger-line" />
                        </div>

                        <div id="nav-menu-mobile-items-flex-container">
                            <RenderNavMenuItem name="Services" className="nav-menu-mobile-item" />
                            <RenderNavMenuItem name="Products" className="nav-menu-mobile-item" />
                            <RenderNavMenuItem name="About" className="nav-menu-mobile-item" />
                            <RenderNavMenuItem name="Contact" className="nav-menu-mobile-item" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NavMenu;