// This hook lets the calling component send a callback to be executed when all the images on a page are fully loaded. This is needed because
// useEffect() doesn't account for content loading, so if position or size operations are needed on an element, it must be done after all images
// have loaded, which is when their positions should be finalized
import { useEffect, useRef } from "react";

const useImagesLoaded = (callbackFunction) => {
    const callbackRef = useRef();

    // In theory keeping the callback in a ref isn't needed now but it will be if state is ever added, in which case a "new" function will be sent on
    // re-render, even though the function definition itself might be the same (see: memoize in react)
    useEffect(() => {
        callbackRef.current = callbackFunction;
    }, [callbackFunction]);

    useEffect(() => {
        let imagesLoading = [];
        let imagesLoaded = [];

        const allImagesLoaded = () => {
            return imagesLoaded.length === imagesLoading.length;
        }

        const imageLoaded = (event) => {
            imagesLoaded.push(event.target);

            if (allImagesLoaded())
                callbackRef.current();
        }

        if (imagesLoading.length === 0) {
            imagesLoading = Array.from(document.getElementsByTagName("img"));

            imagesLoading.forEach((image) => {
                if (image.complete)
                    imagesLoaded.push(image);
                else
                    image.addEventListener("load", imageLoaded);
            });

            if (allImagesLoaded())
                callbackRef.current();
        }
    }, []);
}

export default useImagesLoaded;