import * as React from 'react';
import { useEffect, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import './ContactForm.css';

class ContactFormData { }

const ContactForm = (props) => {
    const { register, handleSubmit, getValues, trigger, setValue, formState: { errors } } = useForm();
    let contactFormData = useMemo(() => new ContactFormData(), []);

    const handlePhoneNumberKeyUp = (event) => {
        if (["Backspace", "Delete"].includes(event.key))
            return;

        let currentValue = event.target.value;
        let newValue = "(" + currentValue.replace(/\D/g, "");

        // TR - full regex but requires 7 characters minimum newValue = newValue.replace(/(^.{4})(.{3})(.*)/, "$1)$2-$3");
        newValue = newValue.replace(/^.{4}/, "$&)").replace(/^.{8}/, "$&-");

        event.target.value = newValue;
    };

    const handlePhoneNumberExtKeyUp = (event) => {
        let confirmPhoneExtElement = document.getElementById("contact-text-form-confirm-phone-ext");

        if (event.target.value.length > 0)
            confirmPhoneExtElement.style.visibility = "visible";

        else {
            setValue("confirmPhoneExt", "", { shouldValidate: true })
            confirmPhoneExtElement.style.visibility = "hidden";
        }
    };

    const onContactFormSubmit = (data) => {
        document.getElementById("contact-text-form-submit-button").disabled = true;
        document.getElementById("loading-spinner").style.display = "inline-flex";
        document.getElementById("foreground").style.opacity = ".2";

        setTimeout(async () => {
            let postUrl = process.env.REACT_APP_CONTACT_API_URL;
console.log(postUrl);
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ ...data })
            };

            await fetch(postUrl, requestOptions)
                .then((response) => {
                    document.getElementById("loading-spinner").style.display = "none";
                    document.getElementById("foreground").style.opacity = "1";
                    props.parentUpdateContactFormResponseState(true, response.ok, document.getElementById("contact-container").offsetHeight);
                })
                .catch((response) => {
                    document.getElementById("loading-spinner").style.display = "none";
                    document.getElementById("foreground").style.opacity = "1";
                    props.parentUpdateContactFormResponseState(true, response.ok, document.getElementById("contact-container").offsetHeight);
                });
        }, 3000);
    };

    // Takes a properties string and assigns the value to the given object dynamically at that location, e.g. - contactFormData.fulLName = value
    const assignToObject = useCallback((object, properties, value) => {
        if (typeof properties === "string")
            properties = properties.split(".");

        if (properties.length > 1) {
            let e = properties.shift();
            assignToObject(object[e] = (Object.prototype.toString.call(object[e]) === "[object Object]") ? object[e] : {}, properties, value);
        }
        else
            object[properties[0]] = value;
    }, []);

    const genericFormFieldOnChange = (e) => {
        assignToObject(contactFormData, e.target.name, e.target.value);

        // Set this entry to expire in 1 day (86400000 ms)
        contactFormData.expires = new Date().getTime() + 86400000;

        window.localStorage.setItem('contactFormData', JSON.stringify(contactFormData));
    }

    const preferredContactMethodOnChange = (e) => {
        genericFormFieldOnChange(e);

        changePreferredContactMethod(e.target.selectedOptions[0].value);

        scrollToElement(e.target, window.innerHeight / 2 * -1);
    }

    const changePreferredContactMethod = (selectedValue) => {
        if (selectedValue === "email") {
            document.getElementById("contact-text-form-email-container").style.display = "inline-flex";
            document.getElementById("contact-text-form-phone-container").style.display = "none";
            document.getElementById("contact-text-form-confirm-phone-container").style.display = "none";
        }

        else {
            document.getElementById("contact-text-form-email-container").style.display = "none";
            document.getElementById("contact-text-form-phone-container").style.display = "inline-flex";
            document.getElementById("contact-text-form-confirm-phone-container").style.display = "inline-flex";

            if (document.getElementById("contact-text-form-phone-ext").value.length > 0)
                document.getElementById("contact-text-form-confirm-phone-ext").style.visibility = "visible";
        }
    }

    const scrollToElement = (element, offset) => {
        const headerRect = document.getElementById("header").getBoundingClientRect();
        const backgroundElement = document.getElementById("background");

        const newTop = element.getBoundingClientRect().top + backgroundElement.scrollTop - (headerRect.height + 10) + offset;
        backgroundElement.scrollTo(0, newTop);
    }

    const reinitializeReactHookFormFromLocalStorage = useCallback((localStorageContactFormData) => {
        for (const property in localStorageContactFormData) {
            // Set the field in RHF
            setValue(property, localStorageContactFormData[property], { shouldValidate: false });

            // Update contactFormData which is the object used to track form updates by the user
            assignToObject(contactFormData, property, localStorageContactFormData[property]);
        }
    }, [setValue, assignToObject, contactFormData]);

    // Scroll to any focused fields as a result of validation errors so long as the active element isn't a button
    useEffect(() => {
        let activeElement = document.activeElement;

        // setTimeout hack is to get around a bug on android where the pop-up keyboard prevents scrolling
        if (["input", "textarea"].includes(activeElement.tagName.toLowerCase()) && !["submit", "button"].includes(activeElement.type))
            setTimeout(() => { scrollToElement(activeElement, window.innerHeight / 4 * -1) }, 300 );
    });

    /* There is an issue here where react-hook-form can't reset itself if the user hits the back button. Unfortunately, even in React 17.0.2 the DOM 
        isn't loaded when useEffect() fires (even with an empty dependency array) like they claim, so the current values in the form can't be used 
        to reinitialize react-hook-form through things like document.getElementById(). As a workaround, the values from the form are put into 
        localstorage as the user makes changes, and react-hook-form is reinitialized from it here. Maybe I'm missing something, but I've tried all 
        sorts of RHF resets, state change re-rerendering, etc. Maybe useEffect() will be changed in the future, and can revisit this then. 
        
        Update - It seems the DOM is loaded on other pages in useEffect, so maybe this has something to do with the combination of useEffect() and
        React-Hook-Form?
        */
    useEffect(() => {
        let localStorageContactFormData = JSON.parse(window.localStorage.getItem("contactFormData"));

        if (localStorageContactFormData !== null) {
            reinitializeReactHookFormFromLocalStorage(localStorageContactFormData);

            // Also, manually set the preferred contact method so the extra email/phone textboxes show up. At this point react-hook-form is 
            // properly reloaded so can use getValues()
            if (getValues("preferredContactMethod").length > 0)
                changePreferredContactMethod(localStorageContactFormData.preferredContactMethod);

            // Remove any local storage that's expired
            if (contactFormData.expires < new Date().getTime())
                window.localStorage.removeItem("contactFormData");
        }
    }, [reinitializeReactHookFormFromLocalStorage, getValues, contactFormData.expires]);

    return (
        <>
            <div id="contact-text-container" className="section-text-container">
                <div id="contact-text-header-1" className="section-text-header-1">Contact Us</div>
                <p>Let us know how we may assist you by filling out the form below.</p>
                <p>A representative will reach out to you via your preferred contact method within 1 business day.</p>
            </div>

            <form id="contact-text-form" onSubmit={handleSubmit(onContactFormSubmit)}>
                <div className="contact-text-form-row">
                    <div className="errorMessage">{errors.fullName && "Full name is required"}</div>
                    <input {...register("fullName", { required: true, onChange: (e) => genericFormFieldOnChange(e) })} placeholder="Full Name*" />
                </div>
                <div className="contact-text-form-row">
                    <div className="errorMessage">{errors.companyName && "Company name is required"}</div>
                    <input {...register("companyName", { required: true, onChange: (e) => genericFormFieldOnChange(e) })} placeholder="Company Name*" />
                </div>
                <div className="contact-text-form-row">
                    <div className="errorMessage">{errors.preferredContactMethod && "Preferred contact method is required"}</div>
                    <select id="contact-text-form-preferred-contact-method" {...register("preferredContactMethod", { required: true, onChange: (e) => preferredContactMethodOnChange(e) })} placeholder="Preferred Contact Method*" defaultValue="">
                        <option value="" disabled hidden>How would you prefer we contact you?</option>
                        <option value="email">Email</option>
                        <option value="phone">Phone Call</option>
                    </select>
                </div>
                <div className="contact-text-form-row">
                    <div className="errorMessage">{errors.email && errors.email.message}</div>
                    <div id="contact-text-form-email-container">
                        <input id="contact-text-form-email" {
                            ...register("email", {
                                validate: {
                                    required: value => {
                                        if (getValues("preferredContactMethod") === "email" && !value)
                                            return "Email address is required";

                                        return true;
                                    },
                                    regex: value => {
                                        if (getValues("preferredContactMethod") === "email" && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value))
                                            return "Invalid email address format";

                                        return true;
                                    },
                                    compareToConfirmation: value => {
                                        if (getValues("preferredContactMethod") === "email" && getValues("confirmEmail") !== value)
                                            return "Confirmation email address doesn't match original";

                                        return true;
                                    }
                                },
                                onChange: (e) => genericFormFieldOnChange(e)
                            })} placeholder="Email Address*" />
                        <input id="contact-text-form-confirm-email" {
                            ...register("confirmEmail", {
                                validate: {
                                    triggerOriginalValidation: value =>
                                    {
                                        trigger("email");
                                    }
                                },
                                onChange: (e) => genericFormFieldOnChange(e)
                            })} placeholder="Confirm Email Address*" />
                    </div>
                </div>
                <div className="contact-text-form-row">
                    <div className="errorMessage">{errors.phone && errors.phone.message}</div>
                    <div id="contact-text-form-phone-container">
                        <input id="contact-text-form-phone" {
                            ...register("phone", {
                                validate: {
                                    required: value => {
                                        if (getValues("preferredContactMethod") === "phone" && !value)
                                            return "Phone number is required";

                                        return true;
                                    },
                                    regex: value => {
                                        if (getValues("preferredContactMethod") === "phone" && !/\([0-9]{3}\)[0-9]{3}-[0-9]{4}/.test(value))
                                            return "Invalid phone number format";

                                        return true;
                                    },
                                    triggerConfirmPhoneValidation: value => {
                                        trigger("confirmPhone");
                                    }
                                },
                                onChange: (e) => genericFormFieldOnChange(e)
                            })} onKeyUp={handlePhoneNumberKeyUp} placeholder="Phone Number*" maxLength="13" />

                        <input id="contact-text-form-phone-ext" {
                            ...register("phoneExt", {
                                validate: {
                                    triggerConfirmPhoneExtValidation: value => {
                                        trigger("confirmPhoneExt");
                                    }
                                },
                                onChange: (e) => genericFormFieldOnChange(e)
                            })} placeholder="Extension (Optional)" onKeyUp={handlePhoneNumberExtKeyUp} />
                    </div>
                </div>

                <div className="contact-text-form-row">
                    <div className="errorMessage">{(errors.confirmPhone && errors.confirmPhone.message) || (errors.confirmPhoneExt && errors.confirmPhoneExt.message)}</div>
                    <div id="contact-text-form-confirm-phone-container">
                        <input id="contact-text-form-confirm-phone" {
                            ...register("confirmPhone", {
                                validate: {
                                    compareToOriginal: value => {
                                        if (getValues("preferredContactMethod") === "phone" && getValues("phone") !== value)
                                            return "Confirmation phone number doesn't match original";

                                        return true;
                                    }
                                },
                                onChange: (e) => genericFormFieldOnChange(e)
                            })} onKeyUp={handlePhoneNumberKeyUp} placeholder="Confirm Phone*" maxLength="13" />

                        <input id="contact-text-form-confirm-phone-ext" {
                            ...register("confirmPhoneExt", {
                                validate: {
                                    compareToOriginal: value => {
                                        if (getValues("preferredContactMethod") === "phone" && getValues("phoneExt").length > 0 && getValues("phoneExt") !== value)
                                            return "Confirmation extension doesn't match original";

                                        return true;
                                    }
                                },
                                onChange: (e) => genericFormFieldOnChange(e)
                            })} placeholder="Confirm Extension*" />
                    </div>
                </div>

                <div className="contact-text-form-row">
                    <div className="errorMessage">{errors.requestDetails && "Request details are required"}</div>
                    <textarea id="contact-text-form-request-description" {...register("requestDetails", { required: true, onChange: (e) => genericFormFieldOnChange(e) })} placeholder="Input the details of your request here*" />
                </div>

                <div className="contact-text-form-row">
                    <input id="contact-text-form-submit-button" type="submit" />
                </div>

                <input className="contact-text-form-cinputa" {...register("cFullNameA")} defaultValue="" placeholder="Full Name*" />
                <input className="contact-text-form-cinputa" {...register("cCompanyNameA")} defaultValue="" placeholder="Company Name*" />
                <input className="contact-text-form-cinputa" {...register("cPreferredContactMethodA")} defaultValue="cPreferredContactMethodA" placeholder="Preferred Contact Method*" />
                <input className="contact-text-form-cinputa" {...register("cEmailA")} defaultValue="cEmailA" placeholder="Email Address*" />
                <input className="contact-text-form-cinputa" {...register("cConfirmEmailA")} defaultValue="" placeholder="Confirm Email Address*" />
                <input className="contact-text-form-cinputa" {...register("cPhoneA")} defaultValue="" placeholder="Phone Number*" />
                <input className="contact-text-form-cinputa" {...register("cConfirmPhoneA")} defaultValue="" placeholder="Confirm Phone*" />
                <input className="contact-text-form-cinputa" {...register("cPhoneExtA")} defaultValue="cPhoneExtA" placeholder="Extension (Optional)" />
                <input className="contact-text-form-cinputa" {...register("cConfirmPhoneExtA")} defaultValue="" placeholder="Confirm Extension*" />
                <input className="contact-text-form-cinputa" {...register("cRequestDetailsA")} defaultValue="cRequestDetailsA" placeholder="Input the details of your request here*" />
            </form>
        </>
    );
}

export default ContactForm;