import './Home.css';
import headerImage from '../static/images/header1200.png';
import headerImage2 from '../static/images/header22560.png';
import homeAutomationImage from '../static/images/homeAutomation200.png'
import homeInfrastructureImage from '../static/images/homeInfrastructure200.png'


const Home = (componentProps) => {
    const handleContactButtonClick = (name) => {
        componentProps.indexScrollToSection(name);
    }

    return (
        <>
            <div id="home-container" className="section-container">
                <div id="home-header-container">
                    <div id="home-header-1-container">
                        <div id="home-header-header-1" className="js-loading">In Darkness,</div>
                        <img id="home-header-image-1" src={headerImage} alt="header"></img>
                        <div id="home-header-header-1-line-2" className="js-loading">Innovation Lights the Way</div>
                    </div>

                    <div id="home-header-2-container">
                        <img id="home-header-image-2" className="js-loading" src={headerImage2} alt="header"></img>
                    </div>
                </div>

                <div id="home-text-container" className="section-text-container">
                    <div id="home-text-header-1">IT Consulting for a New Age</div>
                    <div id="home-text-header-2">A one-stop-shop for all of your IT needs.</div>
                    <p>From web &amp; mobile development to REST &amp; SOAP API integrations, Nighthawk TEK
                        has the talent to address a wide variety of the technological challenges businesses face
                        on a day-to-day basis.
                    </p>

                    <div id="home-text-automation-container" className="home-text-subsection-container">
                        <div id="home-text-automation-header" className="home-text-subsection-header">Automation</div>
                        <div id="home-text-automation-content" className="home-text-subsection-content">
                            <img id="home-text-automation-image" src={homeAutomationImage} alt="Automation"></img>
                            <p>Although project management methodologies are essential in today's development lifecycle, developers and DBAs often spend much of their time on mundane tasks
                                such as auditing. The same can be said for administrative resources that are forced to allocate hours simply copying and pasting data from one spreadsheet to
                                another, typically as a workaround to a more complex underlying problem. A business's employees are its most valuable resource. Why have their time spent on
                                menial tasks when instead they could be contributing to projects that will have a greater impact on the bottom line? Whether it's a complex, full-stack .NET
                                solution or a simple excel macro, many of these day-to-day employee responsibilities can be automated and taken off their plates so that they can focus their
                                attention on more critical areas.
                            </p>
                        </div>
                    </div>

                    <div id="home-text-integration-container" className="home-text-subsection-container">
                        <div id="home-text-integration-header" className="home-text-subsection-header">Integration</div>
                        <div id="home-text-integration-content" className="home-text-subsection-content">
                            <p>Seamless interfaces to a large variety of 3rd parties, complete with customized error-handling. Facebook, Twitter, SMS, credit card processors&mdash;the type of
                                service doesn't matter. If it has an interface available we can connect your business to it.
                            </p>
                        </div>
                    </div>

                    <div id="home-text-design-container" className="home-text-subsection-container">
                        <div id="home-text-design-header" className="home-text-subsection-header">Design/Development</div>
                        <div id="home-text-design-content" className="home-text-subsection-content">
                            <p>Mobile websites, desktop sites, shopping carts, portals, scheduling systems, content management systems&mdash;there's no limit to how a business's online presence can
                                be enhanced. By asking the right questions and incorporating feedback, we can determine what a typical user experience should entail and come up with an appropriate
                                design and project plan. Once approved, it's just a matter of putting code to compiler, so to speak, and the project will be delivered!
                            </p>
                        </div>
                    </div>

                    <div id="home-text-infrastructure-container" className="home-text-subsection-container">
                        <div id="home-text-infrastructure-header" className="home-text-subsection-header">Infrastructure</div>
                        <div id="home-text-infrastructure-content" className="home-text-subsection-content">
                            <img id="home-text-infrastructure-image" src={homeInfrastructureImage} alt="infrastructure"></img>
                            <p>Nighthawk TEK also has extensive experience in a large number of infrastructure-related areas. From building servers and desktops to troubleshooting network problems
                                and providing general tech support, we can supplement your staff on a short or long-term basis in these areas as well. In fact, when it comes to many of the software
                                solutions presented in the previous sections, past experience has shown that collaboration is often required between developers and system engineers to address the
                                inevitable speed bumps that arise (e.g., file permissions, closed firewall ports, etc.). We'll draw upon this past experience to deliver solutions that integrate properly
                                with your current infrastructure.
                            </p>
                        </div>
                    </div>

                    <div id="home-text-contact-container" className="home-text-subsection-container">
                        <div id="home-text-contact-content" className="home-text-subsection-content">
                            <p>Contact us today and we'll work with you to find ways in which we can help enhance your business!</p>
                            <button className="jump-to-contact-us-button" type="button" onClick={() => handleContactButtonClick("contact")}>Contact Us {">>>"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;