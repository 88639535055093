import './NhtIndex.css';
import { useEffect, useRef } from 'react';
import NavMenu from './NavMenu';
import Home from './Home';
import Services from './Services';
import Products from './Products';
import About from './About';
import Contact from './Contact/Contact';
import * as React from 'react';
import useImagesLoaded from "../Hooks/useImagesLoaded";


// Note: CSS code at this level is in index.scss, the rest is in the components themselves

const NhtIndex = () => {
    const homeHeaderImage2Ref = useRef(null);
    const homeTextSubsectionContainersRef = useRef(null);
    let homeHeaderImage2StartingBottom = -1;

    let lastAnimationTime = 0;
    let slideDirection = "left";

    const scrollToSection = (name) => {
        const headerRect = document.getElementById("header").getBoundingClientRect();
        const backgroundElement = document.getElementById("background");
        const sectionContainerElementRect = document.getElementById(name.toLowerCase() + "-container").getBoundingClientRect();

        // TR - sectionContainerElementRect.top represents the top of the element offset by where it's at in the viewport. If it's > 0, the element is further down, so add
        //      the current scroll position to it. If it's < 0, it's further up (and will be negative) so take the current scroll position and substract the element's 
        //      top from it (so it will be scrollTop + -sectionContainerElementRect.top). So the code is the same in both cases...
        const newTop = sectionContainerElementRect.top + backgroundElement.scrollTop - (headerRect.height + 20);

        backgroundElement.scrollTo(0, newTop);
    }

    const backgroundOnScroll = () => {
        if (homeHeaderImage2StartingBottom !== -1)
            homeHeaderImage2Fade();

        // Put a brief pause before processing the sliding sections, otherwise the DOM doesn't get a chance to update their locations
        setTimeout(() => { slideHomeTextSubsections() }, 250);
    }

    function homeHeaderImage2Fade() {
        let newOpacity = 1;

        // With 100% being the bottom of the image to the top of the screen, set the opacity as a percentage of where the bottom of the image currently is within that span to fade it out
        newOpacity = Math.min(1, (homeHeaderImage2Ref.current.getBoundingClientRect().bottom / homeHeaderImage2StartingBottom));
        homeHeaderImage2Ref.current.style.opacity = newOpacity;
    }

    function slideHomeTextSubsections() {
        let animationDelay = .5;
        let fadeInDelay = 1.5;
        let indexesToRemove = [];

        for (const [index, element] of homeTextSubsectionContainersRef.current.entries()) {
            if (isInViewport(element)) {
                let subsectionHeaderElement = element.getElementsByClassName("home-text-subsection-header")[0];
                let subsectionContentElement = element.getElementsByClassName("home-text-subsection-content")[0];

                // If the user scrolled a large amount at once, multiple elements will be in the viewport and process at the same time, add an
                // additional second to the animation delay so they don't all slide-in at once
                animationDelay = (Date.now() - lastAnimationTime < 1000) ? animationDelay + 1 : animationDelay;
                fadeInDelay = (Date.now() - lastAnimationTime < 1000) ? fadeInDelay + 1 : fadeInDelay;

                if (subsectionHeaderElement) {
                    subsectionHeaderElement.style.animationName = "slide-in-from-" + slideDirection;
                    subsectionHeaderElement.style.animationDelay = animationDelay + "s";
                    slideDirection = (slideDirection === "left") ? "right" : "left";
                }

                if (subsectionContentElement) {
                    subsectionContentElement.style.animationName = "home-text-subsection-fade-in";
                    subsectionContentElement.style.animationDelay = fadeInDelay + "s";
                }

                lastAnimationTime = Date.now();
                indexesToRemove.push(index);
            }
        };

        // Reverse the order of the indexes to remove before looping, otherwise they get out of sync and don't remove properly
        indexesToRemove.reverse().forEach((index) => {
            homeTextSubsectionContainersRef.current.splice(index, 1);
        });
    }

    function isInViewport(element) {
        const rect = element.getBoundingClientRect();

        return (rect.top < window.innerHeight);
    }

    // Like an OnLoad() (but does NOT account for things that might still be loading like images), React will run this only the 1st time a component is rendered, due to the empty dependency array
    useEffect(() => {
        homeTextSubsectionContainersRef.current = Array.from(document.getElementsByClassName("home-text-subsection-container"));

        document.getElementById("foreground").focus();
    }, []);

    // Anything requiring accurate positioning or sizing of objects within the dom need to use this custom hook or window.onLoad, useEffect won't factor it in
    useImagesLoaded(() => {
        homeHeaderImage2Ref.current = document.getElementById("home-header-image-2");

        // Let the animation start for the two purple text header lines
        document.getElementById("home-header-header-1").classList.remove('js-loading');
        document.getElementById("home-header-header-1-line-2").classList.remove('js-loading');

        // If the device's height is on the smaller side (probably landscape), set the starting point to the bottom of the viewport instead of where the bottom of the image is
        homeHeaderImage2StartingBottom = Math.min(homeHeaderImage2Ref.current.getBoundingClientRect().bottom, window.innerHeight);
    }, []);


    return (
        <>
            {/* Note - having the background and foreground on separate divs causes a scrolling issue, hence the tabIndex of 1 on the foreground & the focus() above */}
            <div id="background" onScroll={() => backgroundOnScroll()}>
                <div id="foreground" tabIndex="1">
                    <NavMenu indexScrollToSection={scrollToSection} />
                    <Home indexScrollToSection={scrollToSection} />
                    <Services />
                    <Products indexScrollToSection={scrollToSection} />
                    <About />
                    <Contact />
                </div>
            </div>

            <div id="loading-spinner">
                <div className="spinner-bar" />
                <div className="spinner-bar" />
                <div className="spinner-bar" />
            </div>
        </>
    );
};

export default NhtIndex;