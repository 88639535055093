import './About.css';

import visionImage from '../static/images/vision850.png';

const About = () => {
    return (
        <>
            <div id="about-container" className="section-container">
                <div id="about-text-header-1" className="section-text-header-1">Our Vision</div>
                <img id="about-text-header-image" src={visionImage} alt="Our Vision"></img>

                <div id="about-text-container" className="section-text-container">
                    <p>No matter what industry a business serves, technology lies at its core. Be it a mobile website, social media presence, or simply facilitating the handling
                        of email&mdash;as a business's tech goes, so goes the business.</p>

                    <p>Our mission is to help these businesses, especially those that are local, identify and fill gaps that could keep them from realizing their full potential.</p>

                    <p>Don't let complacency stifle your business's growth. There's opportunities to evolve hidden amongst the shadows; let Nighthawk TEK be your beacon that
                        brings them to light.</p>

                    <p>With over 20 years of insight and experience, we have the talent to tackle virtually any challenge placed before us. We understand how users think. We know
                        how businesses operate. We have a knack for taking hazy, half-realized ideas and giving them clarity and a succinct scope.</p>

                    <p>Why survive when you can thrive? Contact us today and discover how we can bring your business to the next level!</p>
                </div>
            </div>
        </>
    );
}

export default About;