import { useState } from 'react';
import './Services.css';

import automationSlideImage from '../static/images/automation600.png';
import integrationSlideImage from '../static/images/integration600.png';
import developmentSlideImage from '../static/images/development600.png';
import infrastructureSlideImage from '../static/images/infrastructure600.png';

class SlideContent {
    constructor(title, image, bulletPoints) {
        this.title = title;
        this.image = image;
        this.bulletPoints = bulletPoints;
    }
}

const Services = () => {
    return (
        <>
            <div id="services-container" className="section-container">
                <div id="services-text-container" className="section-text-container">
                    <p id="services-text-header-1" className="section-text-header-1">Available Services</p>
                </div>

                <Slider />
            </div>
        </>
    );
}

const Slider = () => {
    const [reactState, setReactState] = useState({ activeSlide: 1, arrowsChangedToFixedPositioning: false })

    let slides = [new SlideContent("Automation", automationSlideImage, AutomationSlideBulletPoints()),
        new SlideContent("Integration", integrationSlideImage, IntegrationSlideBulletPoints()),
        new SlideContent("Development Technologies", developmentSlideImage, DevelopmentSlideBulletPoints()),
        new SlideContent("Infrastructure", infrastructureSlideImage, InfrastructureSlideBulletPoints())];


    // (this comment is lefftover from the CLASS component version) - If debugging the call is async so need to use the callback function to get the current state correctly
    //this.setState({activeSlide: activeSlideNumber}, () => console.log("state active slidezzz" + this.state.activeSlide));
    const UpdateActiveSlideState = (activeSlideNumber) => { setReactState({ activeSlide: activeSlideNumber }); }

    // Scroll down so that the slider is at the top of the device, then scroll-up half the device's height, then scroll back down half the height of the slider container itself to get it centered on the device
    const SwitchSlide = (slideNumber) => {
        const headerRect = document.getElementById("header").getBoundingClientRect();
        const backgroundElement = document.getElementById("background");
        const allSlidesContainerElement = document.getElementById("services-all-slides-container");
        const sliderContainerRect = document.getElementById("services-slider-container").getBoundingClientRect();

        // Slide down to just below the nav header if the top of the slider is above the viewing area. Also, slide to this same spot if the height of the slider is more than the height
        // of the device (minus the height of the fixed nav header), this will ensure as much of the slider is visible as possible
        if ((sliderContainerRect.top < headerRect.height) || (sliderContainerRect.height > (window.innerHeight - headerRect.height))) {
            const newTop = backgroundElement.scrollTop + (Math.round(sliderContainerRect.top) - headerRect.height);
            backgroundElement.scrollTo(0, newTop);
        }

        // Only slide up if the bottom of the slider is beyond the viewing area AND if the height of the slider is less than the height of the device (minus the height of the fixed header), 
        // otherwise it's too jumpy
        else if ((sliderContainerRect.bottom > window.innerHeight) && (sliderContainerRect.height <= (window.innerHeight - headerRect.height))) {
            const newTop = backgroundElement.scrollTop + (Math.round(sliderContainerRect.bottom) - window.innerHeight + 5);
            backgroundElement.scrollTo(0, newTop);
        }

        const slideOffsetLeft = (document.getElementById("services-slide-" + slideNumber).getBoundingClientRect().left - allSlidesContainerElement.getBoundingClientRect().left) + allSlidesContainerElement.scrollLeft;

        allSlidesContainerElement.scrollTo(slideOffsetLeft, 0);
    }

    const SliderArrowOnClick = (slideNumber, advance) => {
        let newSlideNumber;
        const maxSlides = 4;

        if (advance)
            newSlideNumber = (slideNumber < maxSlides) ? slideNumber + 1 : 1;
        else
            newSlideNumber = (slideNumber > 1) ? slideNumber - 1 : maxSlides;

        UpdateActiveSlideState(newSlideNumber);
        SwitchSlide(newSlideNumber);
    }

    const SliderDotOnClick = (slideNumber) => {
        UpdateActiveSlideState(slideNumber);
        SwitchSlide(slideNumber);
    }

    const TouchEnd = () => {
        for (let x = 1; x <= slides.length; x++) {
            if (isInViewport(document.getElementById("services-slide-" + x))) {
                document.getElementById("services-slider-dot-" + x).className = "services-slider-dot services-slider-dot-selected";
                UpdateActiveSlideState(x);
                return;
            }
        }
    }

    function isInViewport(slideNumber) {
        const slideNumberRect = slideNumber.getBoundingClientRect();
        const sliderRect = document.getElementById("services-slider-container").getBoundingClientRect();

        // TR - The left position is a little touchy and can be off by a pixel or two, so the best way to verify the current active slide is to see 
        //      if its left is anywhere to the left of 1/3 of the width of the slider div. It won't be possible for another slide to be in that 
        //      range at the same time.
        return (slideNumberRect.left >= 0 && slideNumberRect.left <= (sliderRect.right / 3));
    }

    return (
        <>
            <div id="services-slider-container">
                <div id="services-slide-container">
                    <div id="arrow-left" className="services-slider-arrow" onClick={() => SliderArrowOnClick(reactState.activeSlide, false)} />

                    <div id="services-all-slides-container">
                        {slides.map((slideContent, x) =>
                            <Slide key={x} number={x + 1} title={slideContent.title} image={slideContent.image} bulletPoints={slideContent.bulletPoints} touchEndEventHandler={() => setTimeout(() => TouchEnd(), 1200)} />
                        )}
                    </div>

                    <div id="arrow-right" className="services-slider-arrow" onClick={() => SliderArrowOnClick(reactState.activeSlide, true)} />
                </div>

                <div id="services-slider-jump-points">
                    {
                        slides.map((slideContent, x) => {
                            x++;
                            let currentClassName = (x === reactState.activeSlide) ? "services-slider-dot services-slider-dot-selected" : "services-slider-dot";

                            return (
                                <span key={x} id={"services-slider-dot-" + x} className={currentClassName} onClick={() => SliderDotOnClick(x)} />
                            )
                        }
                    )}
                </div>
            </div>
        </>












/*        <div id="services-slider-container">
            <div id="services-slider-and-arrows-container">
                <div id="services-slider">
                    {slideNumbers.map((x) =>
                        <Slide key={x} slideNumber={x} slideContent={slideContent[x - 1]} touchEndEventHandler={() => setTimeout(() => TouchEnd(), 500) } />
                    )}
                </div>

                <div className="services-slider-arrow-container">
                    <div className="services-slider-arrow arrow-right" onClick={() => SliderArrowOnClick(reactState.activeSlide, true)} />
                </div>
            </div>

            <div id="services-slider-jump-points">
                {slideNumbers.map((x) => {
                    let currentClassName = (x === reactState.activeSlide) ? "services-slider-dot services-slider-dot-selected" : "services-slider-dot";

                    return (
                        <span key={x} id={"services-slider-dot-" + x} className={currentClassName} onClick={() => SliderDotOnClick(x)} />
                    )
                }
                )}
            </div>
        </div>
*/
    );
}

const Slide = (props) => {
    return (
        <div id={"services-slide-" + props.number} className="services-slide" onTouchEnd={props.touchEndEventHandler}>
            <div className="services-slide-title">{props.title}</div>

            <img src={props.image} alt={props.title.toString().toLowerCase()}></img>
            {/*
            {slideNumbers.map((x) =>
                <Slide key={x} slideNumber={x} slideContent={slideContent[x - 1]} touchEndEventHandler={() => setTimeout(() => TouchEnd(), 500)} />
            )}
            */}

            <div className="services-slide-bullet-points">
                {props.bulletPoints}
            </div>
        </div>
    );
}













/*    return (
        <div id={"services-slide-" + props.slideNumber} className="services-slide" onTouchEnd={props.touchEndEventHandler}>
            {props.slideContent}
        </div>
    );
*/


const IntegrationSlideBulletPoints = () => {
    return (
        <>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />RESTful API Interfaces</div>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />SOAP Integrations</div>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />RPC</div>

            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Producers/Consumers</div>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Webhooks</div>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />MS COM Development</div>
        </>
    );











/*    return (
        <>
            <div className="services-slide-title">Integration</div>
            <img src={integrationSlideImage} alt="integration"></img>
            <div className="services-slide-bullet-points">
                <div className="services-slide-bullet-point"><span className="services-slide-bullet" />RESTful API Interfaces</div>
                <div className="services-slide-bullet-point"><span className="services-slide-bullet" />SOAP Integrations</div>
                <div className="services-slide-bullet-point"><span className="services-slide-bullet" />RPC</div>

                <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Producers/Consumers</div>
                <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Webhooks</div>
                <div className="services-slide-bullet-point"><span className="services-slide-bullet" />MS COM Development</div>
            </div>
        </>
    );
*/
}

const AutomationSlideBulletPoints = () => {
    return (
        <>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Queue Systems</div>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />SMS Processing</div>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />VCS Utilities</div>

            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Social Media Tools</div>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Email Management</div>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Azure Lifecycle Control</div>
        </>
    );








/*    return (
        <>
            <div className="services-slide-title">Automation</div>
            <img src={automationSlideImage} alt="automation"></img>
            <div className="services-slide-bullet-points">
                <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Queue Systems</div>
                <div className="services-slide-bullet-point"><span className="services-slide-bullet" />SMS Processing</div>
                <div className="services-slide-bullet-point"><span className="services-slide-bullet" />VCS Utilities</div>

                <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Social Media Tools</div>
                <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Email Management</div>
                <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Azure Lifecycle Control</div>
            </div>
        </>
    );
}
*/
}

const DevelopmentSlideBulletPoints = () => {
    return (
        <>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />.NET 6/Core/MVC</div>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Kendo UI</div>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Sass/CSS</div>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Oracle/SQL Server</div>

            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />React</div>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Javascript/ES12</div>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />JSON/XML</div>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />PostgreSQL/MySQL</div>

            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />C#</div>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />jQuery</div>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Powershell</div>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />...And Many More!</div>
        </>
    );
}

const InfrastructureSlideBulletPoints = () => {
    return (
        <>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Domain Registration</div>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Cloud Solutions</div>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Backup Strategy</div>

            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />TLS/SSL Configuration</div>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Networking</div>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Redundancy</div>

            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Version Control</div>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Virtualization</div>
            <div className="services-slide-bullet-point"><span className="services-slide-bullet" />Tech Support</div>
        </>
    );
}

export default Services;